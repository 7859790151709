// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}


































































































































































































.change-password {
  position: relative;
  padding: 50px 60px;

  .close-modal {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.25s ease;

    &:hover {
      opacity: 1;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: #000;

    & + * {
      margin-top: 40px;
    }
  }

  .form-success {
    margin-top: 25px;

    button {
      width: 100%;
    }
  }

  &__form {
    .form-item + .form-item {
      margin-top: 15px;
    }
  }

  .form-error {
    font-size: 13px;
    line-height: 18px;
    color: #f5383b;
  }

  &-footer {
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    color: #999999;
  }
}

.profile-page {
  background: #fff;
  padding: 50px 0;
}

.profile {
  &-top {
    margin-bottom: 35px;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: #4f4f4f;

    &__name {
      font-size: 25px;
      line-height: 43px;
      color: #333;
    }

    &__contacts {
      font-size: 16px;
      line-height: 28px;
      color: #808080;
    }

    button {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #1e72c2;
      padding: 0;
      margin: 0;
      border: 0;
      background: none;
      border-bottom: 1px dashed #1e72c2;
      cursor: pointer;
    }
  }

  &-body {
    width: 645px;
    margin: 0 auto;
  }
}
